.react-calendar {
  color: var(--chakra-colors-leadpro-500);
  width: 350px;
  max-width: 100%;
  background: white;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;

  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;

  *,
  *:before,
  *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  button {
    margin: 0;
    border: 3px solid white;
    outline: none;

    &:enabled:hover {
      cursor: pointer;
    }

    &:enabled:focus {
      outline: none;
    }
  }

  .react-calendar--doubleView {
    width: 700px;

    .react-calendar__viewContainer {
      display: flex;
      margin: -0.5em;

      * {
        width: 50%;
        margin: 0.5em;
      }
    }
  }

  .react-calendar__navigation {
    display: flex;
    height: 44px;
    margin-bottom: 1em;

    button {
      min-width: 44px;
      background: none;
      border-radius: var(--chakra-radii-md);

      &:disabled {
        background-color: var(--chakra-colors-leadpro-100);
        color: var(--chakra-colors-leadpro-300);
      }
      &:enabled:hover,
      &:enabled:focus {
        background-color: var(--chakra-colors-leadpro-100);
      }
    }
  }

  .react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.75em;

    abbr {
      text-decoration: none;
      color: var(--chakra-colors-leadpro-300);
    }
  }

  .react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
  }

  .react-calendar__month-view__weekNumbers {
    .react-calendar__tile {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.75em;
      font-weight: bold;
    }
  }

  .react-calendar__month-view__days__day--weekend {
    color: var(--chakra-colors-red-500);
  }

  .react-calendar__month-view__days__day--neighboringMonth {
    color: var(--chakra-colors-leadpro-300);
  }

  .react-calendar__year-view,
  .react-calendar__decade-view,
  .react-calendar__century-view {
    .react-calendar__tile {
      padding: 2em 0.5em;
    }
  }

  .react-calendar__tile {
    max-width: 100%;
    padding: var(--chakra-space-2);
    background: none;
    text-align: center;
    line-height: 16px;
    border-radius: var(--chakra-radii-md);

    &:disabled {
      background-color: var(--chakra-colors-leadpro-100);
      color: var(--chakra-colors-leadpro-300);
    }
    &:enabled:hover,
    &:enabled:focus {
      background-color: var(--chakra-colors-leadpro-100);
    }
  }

  .react-calendar__tile--now {
    background: var(--chakra-colors-green-200);

    &:enabled:hover,
    &:enabled:focus {
      background: var(--chakra-colors-green-100);
    }
  }

  .react-calendar__tile--hasActive {
    background: var(--chakra-colors-leadpro-500);
    color: white;

    &:enabled:hover,
    &:enabled:focus {
      background: var(--chakra-colors-leadpro-400);
    }
  }

  .react-calendar__tile--active {
    background: var(--chakra-colors-leadpro-500);
    color: var(--chakra-colors-leadpro-50);

    &:enabled:hover,
    &:enabled:focus {
      background: var(--chakra-colors-leadpro-400);
    }
  }
}

.react-calendar--selectRange {
  .react-calendar__tile--hover {
    background-color: var(--chakra-colors-leadpro-100);
  }
}
