.root {
  p,
  ul {
    margin-bottom: 14px;
  }

  ul {
    list-style: disc;
  }

  a {
    text-decoration: underline;
  }
}
