.squareAvatar {
  img {
    border-radius: 0;
    object-fit: contain;
  }
}

.mdRoundedAvatar {
  img {
    border-radius: 0.375rem;
    object-fit: contain;
  }
}
