.overflow-hidden {
  overflow: hidden;
}

.h-screen {
  height: 100vh;
}

.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
