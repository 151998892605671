// example how to override colors for all settings check node_modules/chartist/dist/scss/settings/_chartist-settings.scss
$ct-series-colors: (
  #0066ff,
  #960df2,
  #1b94a1,
  #f05b4f,
  #453d3f,
  #59922b,
  #0544d3,
  #6b0392,
  #d17905,
  #dda458,
  #eacf7d,
  #86797d,
  #b2c326,
  #6188e2,
  #a748ca
);

@import "node_modules/chartist/dist/scss/chartist";
@import "node_modules/chartist-plugin-tooltips-updated/dist/chartist-plugin-tooltip.scss";

.ct-chart-pie .ct-label {
  color: white;
  fill: white;
}

$chart-tooltip-bg: #17191b;
$chart-tooltip-color: white;

.ct-horizontal {
  display: none;
}

.ct-label.ct-horizontal.ct-end {
  display: block;
  text-align: center;
  line-height: 2;
}

.chartist-tooltip {
  background: $chart-tooltip-bg;
  color: $chart-tooltip-color;
  font-weight: normal;
  border-radius: 3px;
  &:before {
    border-top-color: $chart-tooltip-bg;
  }
}
