.mentionsSingleLine {
  .mentionsSingleLine__control {
    font-family: monospace;

    .mentionsSingleLine__input:focus {
      outline: none;
    }

    .mentionsSingleLine__highlighter {
      border: none !important;
    }

    .mentionsSingleLine__mention {
      background-color: var(--chakra-colors-blue-200);
      border-radius: var(--chakra-radii-sm);
      box-shadow: 0 0 0 1px var(--chakra-colors-gray-200);
    }
  }

  .mentionsSingleLine__suggestions__list {
    box-shadow: var(--chakra-shadows-lg);
    border: 1px solid var(--chakra-colors-gray-200);
    border-radius: var(--chakra-radii-md);
    overflow: hidden;
  }

  .mentionsSingleLine__suggestions__item {
    padding: var(--chakra-space-1) var(--chakra-space-2);
    text-align: center;
  }

  .mentionsSingleLine__suggestions__item--focused {
    background-color: var(--chakra-colors-blue-50);
  }
}

.mentionsMultiLine {
  .mentionsMultiLine__control {
    font-family: monospace;
    min-height: 84px;
    padding: var(--chakra-space-3);

    .mentionsMultiLine__input {
      padding: var(--chakra-space-3);
      border-radius: var(--chakra-radii-md);
      border: 1px solid var(--chakra-colors-gray-300);
    }

    .mentionsMultiLine__input:hover {
      border-color: var(--chakra-colors-gray-200);
    }

    .mentionsMultiLine__input:focus {
      outline: none;
      box-shadow: 0 0 0 3px rgba(00, 00, 00, 0.1);
      border-color: transparent;
    }

    .mentionsMultiLine__highlighter {
      border: none !important;
    }

    .mentionsMultiLine__mention {
      background-color: var(--chakra-colors-blue-200);
      border-radius: var(--chakra-radii-sm);
      box-shadow: 0 0 0 1px var(--chakra-colors-gray-200);
    }
  }

  .mentionsMultiLine__suggestions__list {
    box-shadow: var(--chakra-shadows-lg);
    border: 1px solid var(--chakra-colors-gray-200);
    border-radius: var(--chakra-radii-md);
    overflow: hidden;
  }

  .mentionsMultiLine__suggestions__item {
    padding: var(--chakra-space-1) var(--chakra-space-2);
    text-align: center;
  }

  .mentionsMultiLine__suggestions__item--focused {
    background-color: var(--chakra-colors-blue-50);
  }
}
