.root {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 800;
    line-height: 1.3;
    margin-bottom: 0.75em;
  }

  h2:not(:first-child),
  h3:not(:first-child),
  h4:not(:first-child) {
    margin-top: 0.5em;
  }

  h4,
  h5,
  h6 {
    font-weight: bold;
  }
  h1 {
    font-size: 2em;
  }
  h2 {
    font-size: 1.75em;
  }
  h3 {
    font-size: 1.5em;
    font-weight: 500;
  }
  h4 {
    font-size: 1.2em;
  }
  h5 {
    font-size: 1em;
  }
  h6 {
    font-size: 1em;
  }
  hr {
    display: block;
    height: 0;
    border: 0;
    border-top: 1px solid #aaa;
    border-bottom: 1px solid #eee;
    margin: 1em 0;
    padding: 0;
  }
  pre {
    white-space: pre;
    white-space: pre-wrap;
    word-wrap: break-word;
  }

  p,
  ul {
  }

  hr {
    opacity: 0.2;
    border-bottom: 0;
  }

  ul li:not(:last-child) {
    margin-bottom: 1em;
  }

  a {
    text-decoration: underline;
  }

  p,
  ul,
  .image {
    margin-bottom: 1em;
  }

  li {
    margin-left: 1em;
  }

  p:last-child {
    margin-bottom: 0;
  }

  ul {
    list-style-type: disc;
  }
}
